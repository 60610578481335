<template>
    <div>
        <el-footer class="footer">
            <div class="footer-main">
                <div class="flex">
                    <div class="flex flex-h-center flex1">
                        <div>
                            <div class="color-white">{{$t('关于考试')}}</div>
                            <div class="margin-t">
                                <el-link target="_blank" href="https://pte.pearson.com.cn/PTE/knowpte">{{$t('关于pte')}}</el-link>
                            </div>

                            <div class="margin-t">
                                <el-link target="_blank"
                                    href="https://app.jingsocial.com/artview/index?openid=o6Z381CUQGo9HZJkOhhsnWoqBHro&wid=225&ord=0&rid=392275&whoshareopenid=o6Z381JrSCCCcEoJd03PYPigAH-k&whocurrentshareopenid=o6Z381CUQGo9HZJkOhhsnWoqBHro&kol=&relation_type=0&relation_id=&relation_extra_id=&jdt=Q%253D%253DeyJyZWxhdGlvbl90eXBlIjozLCJzZW5kX2NoYW5uZWxfdHlwZSI6InN5c3RlbV9tZW51X3NlbmQiLCJzZW5kX2NoYW5uZWxfb2JqX2lkIjoiODM5Iiwic2VuZF9jaGFubmVsX2V4dHJhX29ial9pZCI6IjMwMTE3Iiwic2VuZF9jaGFubmVsX2V4dHJhX29ial9pZDIiOiJcdTgwMDNcdThiZDVcdTYyYTVcdTU0MGQif">报名流程</el-link>
                            </div>

                            <div class="margin-t">
                                <el-link target="_blank"
                                    href="https://www.pearsonpte.com.cn/pte-academic/test-format">{{$t('考试结构')}}</el-link>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-h-center flex1 w0">
                        <div>
                            <div class="color-white">{{$t('PTE课程')}}</div>
                            <div v-for="(course,index) in $store.getters.getCourses('PTE')" :key="index" class="margin-t">
                                <el-link @click="to('/media?tag=course&id=' + course.id, 'PTE')">{{course.title}}</el-link>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-h-center flex1 w0">
                        <div>
                            <div class="color-white">{{$t('关注我们')}}</div>
                            <div class="margin-t">
                                <el-link @click="show(1, '微信公众号')">{{$t('微信公众号')}}</el-link>
                            </div>
                            <div class="margin-t">
                                <el-link @click="show(0, '微信')">{{$t('微信')}}</el-link>
                            </div>
                            <div class="margin-t">
                                <el-link @click="show(2, '小红书')">{{$t('小红书')}}</el-link>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-h-center flex1 w0">
                        <div>
                            <div class="color-white">{{$t('APP下载')}}</div>
                            <div class="margin-t">
                                <el-link @click="show(3, '')">{{$t('IOS版')}}</el-link>
                            </div>
                            <div class="margin-t">
                                <el-link @click="show(3, '')">{{$t('安卓版')}}</el-link>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-h-center flex1 w0">
                        <div>
                            <div class="color-white">{{$t('联系我们')}}</div>
                            <div class="margin-t">
                                <el-link @click="show(0, '海澳小助手')">{{$t('海澳小助手')}}</el-link>
                            </div>
                            <div class="margin-t">
                                <el-link @click="show(0, '加入我们')">{{$t('加入我们')}}</el-link>
                            </div>
                            <div class="margin-t">
                                <el-link @click="show(0, '反馈意见')">{{$t('反馈意见')}}</el-link>
                            </div>
                        </div>
                    </div>
                </div>

                <el-divider></el-divider>

                <div class="flex">

                    <el-image class="" src="/web/image/logo2.png" style="height:80px;margin-top: 20px;"></el-image>

                    <div style="margin-left:20px">
                        <div class="margin-t color-white"><i class="el-icon-phone-outline"></i><span class="margin-x">+61
                                420
                                589
                                958</span></div>
                        <div class="margin-t color-white"><i class="el-icon-s-promotion"></i><span
                                class="margin-x">ptefighter@gmail.com</span></div>
                        <div class="margin-t color-white"><i class="el-icon-map-location"></i><span class="margin-x"> Suite
                                102,
                                755
                                George St, Haymarket, Sydney 2000, NSW</span></div>

                        <div class="margin-lt color-white">
                            <a class="color-white ul-hover" href="https://beian.miit.gov.cn/"
                                target="_blank">{{$t('网站备案号：皖ICP备2022016658号')}}</a>
                        </div>
                    </div>

                    <div style="margin-left:30px">
                        <el-image v-if="$store.state.setting" class="" :src="$host + $store.state.setting.kf_qrcode"
                            style="height:120px;width:120px;"></el-image>
                        <div class="center color-white st">{{$t('微信客服')}}</div>
                    </div>

                    <div style="margin-left:30px">
                        <el-image class="" :src="$host + $store.state.setting.gzh_qrcode"
                            style="height:120px;width:120px;"></el-image>
                        <div class="center color-white st">{{$t('微信公众号')}}</div>
                    </div>

                    <div style="margin-left:30px">
                        <el-image class="" :src="$host + $store.state.setting.app_qrcode"
                            style="height:120px;width:120px;"></el-image>
                        <div class="center color-white st">APP</div>
                    </div>
                </div>
            </div>
        </el-footer>

        <el-dialog :class="{ 'no-bg': !title }" :visible.sync="dialogVisible" width="480px" center>
            <h3 slot="title">{{ title }}</h3>
            <el-image style="width:100%;height:auto;" :src="src"></el-image>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "CFooter",
    data() {
        return {
            dialogVisible: false,
            title: "扫码咨询",
            src: "",
        };
    },
    methods: {
        to(url, mode) {
            this.$store.commit("setMode", mode);
            this.$router.push(url);
        },

        show(tag, title) {
            this.title = title;
            if (tag == 0) {
                this.src = this.$host + this.$store.state.setting.kf_qrcode;
            } else if (tag == 1) {
                this.src = this.$host + this.$store.state.setting.gzh_qrcode;
            } else if (tag == 2) {
                this.src = "/web/image/home/xhs.jpeg"
            } else if (tag == 3) {
                this.src = `/web/image/home/app_qrcode${this.$store.state.lang}.jpg`
            }
            this.dialogVisible = true;
        },
    },
};
</script>
<style scoped>
.footer-main {
    background-color: #2d2e32;
    padding: 40px 15%;
}


.footer {
  z-index: 10;
  position: relative;
  padding: 0 !important;
}

.footer>>>.el-link--inner {
    color: #999;
}

.footer>>>.el-link--inner:hover {
    color: #ffffff;
}

.no-bg>>>.el-dialog {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}

.no-bg>>>.el-dialog__close {
  color: #fff;
}

</style>
  